<template>

<div class="bg-gradient-to-r from-blue-900 h-full to-blue-700  p-3.5">
  
<h1 class="prose sh text-10vw ml-2 mr-2 text-yellow-400">
  <span class="lima">⏚</span> Electricidad <span class="text-white">&</span> telecomunicaciones
</h1>

<h1 class="prose sh text-10vw pb-6 text-white">

Desarrollo de software <span class="text-yellow-400">&</span> hardware
</h1>


<div class="grid mx-auto grid-cols-2 mt-2 gap-2">
<div class="drop-shadow-md text-white bg-black bg-opacity-10 rounded-md p-3.5">
      <div class="bg-black rounded-md bg-opacity-60 p-4">
      <h1 class="prose text-3xl">Nosotros</h1><br>
      Brindamos servicios de asesoría y ejecución de proyectos gracias a la experiencia de nuestro equipo compuesto
      por profesionales de la ingeniería eléctrica y las telecomunicaciones.<br><br>
      Grupo UTP tiene presencia en el mercado desde hace mas de 12 años brindado soluciones a sectores como la Banca, Salud, Educación, Comercio y Transporte.
     </div>
      </div> 
   


 <div class="drop-shadow-md text-black bg-opacity-10 bg-white rounded-md p-3.5">
      <div class="bg-white rounded-md bg-opacity-100 p-4">
      <h1 class="prose text-3xl">Nuestros Clientes</h1><br>
   

<img class="logo inline m-auto mr-4" alt="Supermercados Colonial" src="@/assets/colonial.webp">
<img class="logo inline m-auto mr-4" alt="Latam Logistic HN" src="@/assets/latam.webp">
<img class="logo inline m-auto mr-4" alt="Bolivar Trading" src="@/assets/bt.webp">
<img class="logo inline m-auto mr-4" alt="Centro de Fertilidad Nacer - IVF" src="@/assets/ivf.png">
<img class="logo inline m-auto mr-4" alt="Laboratorio de Diagnostico Clínico - LDC" src="@/assets/ldc.webp">
<img class="logo inline m-auto mr-4" alt="Agencia Naviera Europea" src="@/assets/anave.webp">
<img class="logo inline m-auto mr-4" alt="Mundiofertas" src="@/assets/mundiofertas.webp">
<img class="logo inline m-auto mr-4" alt="Navis Internet" src="@/assets/navis.webp">
<img class="logo inline m-auto mr-4" alt="Banco de Occidente" src="@/assets/occidente.webp">

     </div>
      </div> 


</div>

</div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default  defineComponent({
setup(){

return{

}
}
})
</script>

<style scoped lang="scss">

.logo{
  width: 120px;
  height:auto;
}


.text-10vw{
  font-size: 10vw;
  line-height: 8vw;
}
</style>>
