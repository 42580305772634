<template>
<entorno/>
  
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

import entorno from "@/components/entorno.vue";
export default  defineComponent({
components:{
entorno
}
})
</script>


<style>

</style>