<template>
<portada/>
  
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import portada from "@/components/portada.vue"
export default  defineComponent({
components:{
portada
}
})
</script>

<style>

</style>