
import { defineComponent, inject, onMounted,Ref,ref } from "@vue/runtime-core";
export default defineComponent({
setup(){
onMounted(()=>{
const tamano = inject("cabecera") as Ref

    for(let i= 0;i<tamano.value; i++){
setTimeout(()=>
{
window.scroll(0,i)
},i*1)
}
 
})

const nombre = ref("")
const telefono = ref("")
const correo = ref("")
const mensaje = ref("")
const Correo = async () =>{

if(nombre.value!="" && telefono.value!="" && correo.value!="" && mensaje.value!=""){    
const parametros = {
body: JSON.stringify({mensaje:"Nombre: "+nombre.value+"<br>Telefono: "+telefono.value+"<br>Correo: "+correo.value+"<br>Mensaje: "+mensaje.value}),
headers:{"content-type":"application/json"},
method: "post" 
}
const consulta = await fetch("https://utp.hn/sg.php?ac=correo",parametros)
const respuesta = await consulta.json()

if(respuesta["respuesta"]=="1"){
  correo.value=""
  telefono.value=""
  nombre.value=""
  mensaje.value=""
  alert("Mensaje enviado")  
}
}else{
      alert("Llene todos los campos")  
}
}








return{
nombre,
telefono,
correo,
mensaje,
Correo
}
}
})
