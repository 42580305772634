import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Portada from '../views/portada.vue'
import Entorno from '../views/entorno.vue'
import Contacto from '../views/contacto.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Portada',
    component: Portada
  },
  {
    path: '/entorno',
    name: 'Entrorno',
    component: Entorno
  }
  ,
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
  path: '/:pathMatch(.*)*', 
  name: 'Portada',
  component: Portada }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
