<template>
<div class="bg-gradient-to-r from-white to-white  p-3.5">
<div class="grid mx-auto grid-cols-2 gap-6">
  
    
<div class="drop-shadow-md   rounded-md p-3.5">
      <div class="rounded-md  p-4">
       <h1 class="prose text-3xl underline decoration-blue-900">Dirección fiscal</h1><br>
      San Pedro Sula, Cortés, Honduras.<br>
      Col. Santa Martha, B35A, C25.<br>
     
     </div>
      </div> 

  <div class="drop-shadow-md   rounded-md p-3.5">
      <div class="rounded-md  p-4">
      <h1 class="prose text-3xl underline decoration-blue-900">Operaciones</h1><br>
      Peña Blanca Cortes, Col 25 de mayo, calle secundaria.<br>
      Correo: contacto@utp.hn<br>
      Tel: 3233-2785
     
     </div>
      </div> 
</div>

</div>
</template>

<script>

</script>

<style>

</style>