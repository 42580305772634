
import { defineComponent, onMounted, provide, ref } from 'vue'
import pie from "@/components/pie.vue"
const __default__ = defineComponent({
  components:{
pie
  },
  setup()
{
const estadologo = ref(false)
const tamano = ref()
onMounted(()=>{
const elemento = document.getElementById("cabecera") as HTMLElement
tamano.value = elemento.offsetHeight

provide('cabecera',tamano)

})
const tiempo = ref()

const logo = ()=>{
tiempo.value = "1000ms"
estadologo.value= true

setTimeout(()=>{
 estadologo.value=false 
}, tiempo.value.slice(0,4))

}




return{
  logo,
  estadologo,
  tiempo
}

}
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0c945000": (_ctx.tiempo)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__