<template>
<contacto/>
  
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

import contacto from "@/components/contacto.vue";
export default  defineComponent({
components:{
contacto
}
})
</script>


<style>

</style>