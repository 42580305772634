<template>
<div class="bg-gradient-to-r from-blue-900 to-blue-500 h-full p-3.5">

<div class="grid mx-auto grid-cols-2 mt-20 mb-20 gap-6">
  <div class="drop-shadow-md text-black bg-opacity-10 bg-white rounded-md p-3.5">
      <div class="bg-white rounded-md bg-opacity-100 p-4">          
      <h1 class="prose text-entorno underline inline">Construcción Electrica y Redes</h1><img class="inline" style="width:5rem" src="@/assets/electricidad.webp"><br>
      Servicio de construcción electrica domiciliar e industrial bajo el codigo NFPA 70.<br><br>
      
      Diseño e instalación de redes de tierra.<br><br>
      Supervisión y avaluo de instalaciones electricas.<br><br>
      Instalación de sistemas de videovigilancia.<br><br>
      Construcción de Redes estructuradas.<br>
     </div>
   
      </div> 
  <div class="drop-shadow-md text-white bg-opacity-10 bg-white rounded-md p-3.5">
      <div class="bg-black rounded-md bg-opacity-80 p-4">
      <h1 class="prose text-entorno underline">Software y Hardware</h1><br>
     Desarrollo de aplicaciones multiplataforma.<br>
     Asesoría e Implementación de TIC'S.<br>
     Tercerización de servicios bajo demanda.<br>
     Suministro de licenciamientos y equipos especializados.<br>
     <div class="bg-white p-6 mt-3 rounded-md">
<img class="inline m-3" style="height:3rem;" src="@/assets/logo.png">
<img class="inline m-3" style="height:3rem;" src="@/assets/php.png">
<img class="inline m-3" style="height:3rem;" src="@/assets/mysql.png">
<img class="inline m-3" style="height:3rem;" src="@/assets/javascript.png">
<img class="inline m-3" style="height:3rem;" src="@/assets/git.png">
<img class="inline m-3" style="height:3rem;" src="@/assets/android.png">
<img class="inline m-3" style="height:3rem;" src="@/assets/csharp.png">
<img class="inline m-3" style="height:3rem;" src="@/assets/kotlin.png">
     </div>
     </div>
      </div> 
</div>






</div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default  defineComponent({
setup(){

return{

}
}
})
</script>
<style lang="scss" scoped>
.electricidad{
    background-image: url("~@/assets/electricidad.png");
}
.desarrollo{
    background-image: url("~@/assets/desarrollo.png");
}

.logo{
height: 24px;
}
.text-entorno{
    font-size: 2vw;
}
</style>
